body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  scrollbar-gutter: stable;
  overflow-y: auto;
  overflow-x: hidden;
}
